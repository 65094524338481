@media screen and (max-width: 1339px) {
  .slider__content{
    padding: 20px
  }
}

@media screen and (max-width: 1199px) {
  .wrapper{
    width: 100%
    padding: 0 10px
    &_min{
      width: 100%
    }
  }
  .head{
    padding: 0 10px
  }
  .nav{
    &__link{
      font-size: rem(15)
    }
  }
  .fancybox-link img{
    max-width: 100%
  }
  .we-offers__item{
    flex: 1 1 100%
  }
  .slider__content{
    font-size: rem(20)
    line-height:normal
  }
  .projects{
    margin: 0 -10px
    &__item{
      flex-flow: row wrap
      &:nth-child(even) .projects__img {
        order: 0
      }
    }
    &__img{
      flex: 1 1 100%
      text-align: center
    }
    &__desc{
      flex: 1 1 100%
      text-align: center!important
      padding: 30px 0
      &:before{
        content: '';
        border-right: 20px solid transparent!important
        border-left: 20px solid transparent!important
        border-top: none!important
        border-bottom: 20px solid #f3f3f3!important
        position: absolute
        top: -20px!important
        left: 50%!important
        right: auto!important
        transform: translateX(-50%)!important;
      }
      &:hover{
        &:before{
          border-bottom-color: #e95701!important
        }
      }
    }
    &__title{
      &:after{
        left: 50%!important
        -webkit-transform: translateX(-50%)
        -moz-transform: translateX(-50%)
        -ms-transform: translateX(-50%)
        -o-transform: translateX(-50%)
        transform: translateX(-50%)
      }
    }
  }
  .slider__scroll{
    display: none
  }
  .slid-clients{
    .slick-slide{
      text-align: center
    }
  }
  .slider{
    .slick-dots{
      display: none!important
    }
    &__content{
      display: none
    }
  }
}

@media screen and (max-width: 1024px){
  .header{
    height: auto
    position: static
    &.fixed{
      height: auto
    }
  }
  .container{
    padding-top: 0
  }
  .head{
    position: relative
    padding: 0 10px 10px
    justify-content: space-between;
  }
  .nav{
    display: block
    position: absolute
    top: -300%;
    left: -10px
    right: -10px
    z-index: 99
    background-color: #fff
    height: auto
    padding 20px 0
    -webkit-transition: all .3s
    -moz-transition: all .3s
    -ms-transition: all .3s
    -o-transition: all .3s
    transition: all .3s
    &.open{
      top: 100%
    }
    &__link{
      display: block
      padding: 8px 20px
    }
  }
  .burger{
    display: block
  }
}
@media screen and (max-width: 570px){
  .head{
    display: block
    text-align: center
    padding-top:15px
  }
  .call-back{
    text-align: center
    padding: 10px 0
  }
  .discussion-project{
    display: block
    &__item{
      text-align: center!important
      padding: 10px 0
    }
  }
  .clients{
    padding: 0
  }
  .we-offers__title{
    font-size: rem(18)
  }
  .we-offers__item{
    padding: 0 10px 30px
  }
  .we-offer{
    padding: 20px 0
  }
  .projects__title{
    font-size: rem(18)
    line-height: normal
  }
  .our-clients{
    padding: 30px 0
  }
  .slick-slide img{
    display: inline-block
  }
  .slid-clients .slick-dots{
    padding: 5px 0
  }
  .slid-clients{
    margin-top: 30px
  }
  .btn-lg{
    max-width: 100%
  }
  .popup{
    max-width: 90%
    padding: 15px
  }
  .fancybox-project{
    font-size: rem(13)
  }
  .projects__info{
    font-size: rem(12)
    padding: 0 10px 10px
  }
  .call-back__phone{
    font-size: rem(24)
  }
}