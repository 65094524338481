html {
  font-size: $em-base;
}
h1 {
  font-size: rem(36);
}
h2 {
  font-size: rem(30);
}
h3 {
  font-size: rem(24);
}
h4 {
  font-size: rem(18);
}
h5 {
  font-size: rem(14);
}
h6 {
  font-size: rem(12);
}
i {
  display: inline-block;
}
.left {
  float: left;
}
.right {
  float: right;
}
.cr {
  float: none;
  clear: both;
  margin: 0;
  padding: 0;
  font-size: 0.1px;
}
.both:after {
  clear: both;
  content: "";
  display: block;
  float: none;
  margin: 0;
  padding: 0;
}
.btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
  border: none;
  background-color: #e95701;
  color: #fff;
  padding: 15px 10px;
  font-size: 15px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  text-transform: uppercase;
}
.btn-lg {
  width: 360px;
  padding: 18px 0;
  border: 1px solid #fff;
  font-size: 25px;
}
.btn-md {
  width: 150px;
  padding: 15px 0;
}
.btn-sm {
  width: 120px;
  padding: 12px 0;
}
.btn-xs {
  width: 100px;
  padding: 10px 0;
}
/* Generated by Font Squirrel (https://www.fontsquirrel.com) on March 15, 2018 */
@font-face {
  font-family: 'icon-fond';
  src: url("../fonts/icon-fond.eot?5e89y1");
  src: url("../fonts/icon-fond.eot?5e89y1#iefix") format('embedded-opentype'), url("../fonts/icon-fond.ttf?5e89y1") format('truetype'), url("../fonts/icon-fond.woff?5e89y1") format('woff'), url("../fonts/icon-fond.svg?5e89y1#icon-fond") format('svg');
  font-weight: normal;
  font-style: normal;
}
i {
/* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon-fond' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
/* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-vfactor-logo .path1:before {
  content: "\e900";
  color: #000;
}
.icon-vfactor-logo .path2:before {
  content: "\e901";
  margin-left: -6.2529296875em;
  color: #fcaf1a;
}
.icon-vfactor-logo .path3:before {
  content: "\e902";
  margin-left: -6.2529296875em;
  color: #000;
}
.icon-vfactor-logo .path4:before {
  content: "\e903";
  margin-left: -6.2529296875em;
  color: #000;
}
.icon-vfactor-logo .path5:before {
  content: "\e904";
  margin-left: -6.2529296875em;
  color: #000;
}
.icon-vfactor-logo .path6:before {
  content: "\e905";
  margin-left: -6.2529296875em;
  color: #000;
}
.icon-vfactor-logo .path7:before {
  content: "\e906";
  margin-left: -6.2529296875em;
  color: #000;
}
.icon-vfactor-logo .path8:before {
  content: "\e907";
  margin-left: -6.2529296875em;
  color: #000;
}
.icon-select:before {
  content: "\e908";
}
.icon-close:before {
  content: "\e909";
}
.icon-mouse-arrow:before {
  content: "\e90b";
}
.icon-mouse:before {
  content: "\e90c";
}
.icon-logo:before {
  content: "\e90d";
}
.icon-zoom:before {
  content: "\e90e";
}
.icon-up:before {
  content: "\e90f";
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
figcaption,
figure,
main {
  display: block;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
dfn {
  font-style: italic;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
audio,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details,
menu {
  display: block;
}
summary {
  display: list-item;
}
canvas {
  display: inline-block;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
body {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-family: 'futuralightcregular', sans-serif;
  font-size: 1.375rem;
  background-color: #fff;
}
a {
  color: #000;
  outline: none !important;
}
.wrapper {
  width: 1200px;
  margin: 0 auto;
}
.wrapper_min {
  width: 1000px;
}
.burger {
  display: none;
  text-align: center;
}
#nav-icon {
  width: 40px;
  height: 30px;
  display: inline-block;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
#nav-icon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #e95701;
  border-radius: 9px;
  -ms-filter: none;
  filter: none;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
#nav-icon span:nth-child(1) {
  top: 0;
}
#nav-icon span:nth-child(2) {
  top: 10px;
}
#nav-icon span:nth-child(3) {
  top: 20px;
}
#nav-icon.open span:nth-child(1) {
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
#nav-icon.open span:nth-child(2) {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  left: -40px;
}
#nav-icon.open span:nth-child(3) {
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.title {
  font-size: 1.938rem;
  font-weight: normal;
  text-transform: uppercase;
  font-family: 'futuralightcregular', sans-serif;
}
.title_center {
  text-align: center;
}
.header {
  background-color: #fff;
  height: 110px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.header.fixed {
  background-color: rgba(255,255,255,0.8);
  height: 80px;
}
.container {
  padding-top: 110px;
}
.head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.head__logo {
  padding-top: 5px;
  padding-bottom: 5px;
}
.head__logo img {
  max-height: 100%;
}
.btn-top-back {
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 9;
}
.nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.nav__link {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.25rem;
  padding: 0 20px;
}
.nav__link:hover {
  color: #ed6a2b;
}
.call-back {
  text-align: right;
  font-family: 'futurademicregular', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}
.call-back__phone {
  display: block;
  font-size: 1.563rem;
  color: #ed6a2b;
  text-decoration: none;
  padding: 0 0 5px;
}
.call-back__order {
  font-size: 0.75rem;
  border-bottom: 1px dashed;
  text-decoration: none;
}
.slider-wrapper {
  position: relative;
}
.slider .slick-dots {
  position: absolute;
  top: 50%;
  right: 50px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.slider__item {
  position: relative;
}
.slider__img {
  max-width: 100%;
}
.slider__content {
  position: absolute;
  max-width: 670px;
  top: 50%;
  left: 50%;
  font-family: 'futuralightcregular', sans-serif;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 3.438rem;
  text-align: center;
  background-color: rgba(255,255,255,0.5);
  padding: 55px 50px;
}
.slider__scroll {
  position: absolute;
  left: 50%;
  width: 45px;
  bottom: 125px;
  z-index: 9;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  text-decoration: none;
  color: #fff;
  text-align: center;
}
.slider__scroll .icon-mouse {
  position: absolute;
  top: 12px;
  left: 0;
  font-size: 4.5rem;
}
.slider__scroll .icon-mouse-arrow {
  position: absolute;
  top: 70px;
  left: 11px;
  font-size: 2rem;
  -webkit-animation: go-right 1.4s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
  animation: go-right 1.4s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
}
.slider-paging__item {
  width: 6px;
  height: 50px;
  background-color: #a2a2a2;
  display: inline-block;
  cursor: pointer;
}
.slider-paging__item.slider-paging__item_h {
  width: 43px;
  height: 3px;
}
.slick-active .slider-paging__item {
  background-color: #e95701;
}
.clients {
  background-color: #f3f3f3;
  padding: 40px 0 65px;
}
.clients .title {
  padding: 50px 0 18px;
}
.clients__desc {
  font-size: 1.313rem;
  line-height: 1.875rem;
  font-family: 'futurabookcregular', sans-serif;
  padding: 23px 0;
}
.we-offer {
  padding: 90px 0;
}
.we-offers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 60px 0;
}
.we-offers__item {
  text-align: center;
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  padding: 0 40px 70px;
  position: relative;
}
.we-offers__item:before {
  content: attr(data-number);
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  font-size: 6.063rem;
  font-family: 'futurademicregular', sans-serif;
  color: #e4e4e4;
  font-weight: bold;
}
.we-offers__title {
  font-family: 'futurademicregular', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 38px 0;
  position: relative;
}
.we-offers__desc {
  font-size: 1.125rem;
  position: relative;
}
.projects {
  padding: 50px 0 0;
}
.projects__btn {
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  text-align: center;
  padding: 45px 0;
}
.projects__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.projects__item:nth-child(even) .projects__img {
  -webkit-order: 1;
  -ms-order: 1;
  order: 1;
}
.projects__item:nth-child(even) .projects__desc {
  text-align: right;
}
.projects__item:nth-child(even) .projects__desc:before {
  left: auto;
  right: -20px;
  border-right: none;
  border-left: 20px solid #f3f3f3;
}
.projects__item:nth-child(even) .projects__desc:hover:before {
  border-left-color: #e95701;
}
.projects__item:nth-child(even) .projects__title:after {
  left: auto;
  right: 0;
}
.projects__img {
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
}
.projects__desc {
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  text-transform: uppercase;
  font-family: 'futurabookcregular', sans-serif;
  background-color: #f3f3f3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  padding: 0 87px;
  position: relative;
}
.projects__desc:before {
  content: '';
  border-right: 20px solid #f3f3f3;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  position: absolute;
  top: 50%;
  left: -20px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
}
.projects__desc:hover {
  background-color: #e95701;
  color: #fff;
}
.projects__desc:hover .projects__info {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -ms-filter: none;
  filter: none;
  opacity: 1;
}
.projects__desc:hover:before {
  border-right-color: #e95701;
}
.projects__info {
  position: absolute;
  top: 20px;
  left: 0;
  bottom: 20px;
  right: 0;
  padding: 0px 40px 20px;
  color: #fff;
  text-transform: none;
  overflow: scroll;
  background-color: #e95701;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: scale(0.8, 0.8);
  -moz-transform: scale(0.8, 0.8);
  -ms-transform: scale(0.8, 0.8);
  -o-transform: scale(0.8, 0.8);
  -webkit-transform: scale(0.8, 0.8);
  -ms-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
}
.projects__title {
  font-size: 1.875rem;
  line-height: 2.188rem;
  font-weight: bold;
  position: relative;
  margin-bottom: 40px;
}
.projects__title:after {
  content: '';
  width: 72px;
  height: 3px;
  background-color: #000;
  position: absolute;
  bottom: -19px;
  left: 0;
}
.projects__text {
  font-size: 0.938rem;
  line-height: 1.25rem;
  color: #545454;
}
.fancybox-hidden {
  display: none;
}
.fancybox-link {
  display: inline-block;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}
.fancybox-link:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../images/svg/zoom.svg");
  background-color: rgba(0,0,0,0.5);
  background-position: center;
  background-repeat: no-repeat;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -o-transition: all 0.7s;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  z-index: 1;
}
.fancybox-link:hover:before {
  -ms-filter: none;
  filter: none;
  opacity: 1;
}
.fancybox-link:hover img {
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}
.fancybox-link img {
  display: block;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.our-clients {
  padding: 87px 0 124px;
}
.slid-clients {
  max-height: 250px;
  margin-top: 60px;
}
.slid-clients .slick-list {
  max-height: 250px;
}
.slid-clients .slick-dots {
  text-align: center;
  padding: 30px 0;
}
.slid-clients .slick-dots li {
  display: inline-block;
  padding: 0 3px;
}
.discuss-project {
  padding: 40px 0;
  background-color: #e95701;
}
.discussion-project {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-transform: uppercase;
}
.discussion-project__item {
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
}
.discussion-project__item:first-child {
  padding: 0 40px;
  text-align: right;
}
.discussion-project .btn {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.discussion-project .btn:hover {
  border-color: #e95701;
  color: #e95701;
  background-color: #fff;
}
.gallery-project {
  padding: 36px 0 15px;
}
.gallery-project_bg {
  background: url("../images/bg-secrion-1.jpg") center/cover no-repeat;
}
.form-gallery {
  text-align: center;
  padding: 15px 0;
}
.styled-select {
  background: #fff url("../images/svg/select.svg") no-repeat 96% 13px;
  height: 34px;
  overflow: hidden;
  width: 300px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 0;
  padding: 0 25px 0 10px;
  font-size: 1.125rem;
  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.styled-select select {
  background: transparent;
  border: none;
  font-size: 14px;
  height: 29px;
  padding: 5px;
  width: 268px;
}
.footer {
  text-align: center;
  font-size: 0.813rem;
  line-height: 1.125rem;
  padding: 50px 0;
}
.footer a {
  font-size: 0.625rem;
}
.footer__logo {
  width: 92px;
  display: block;
  margin: 20px auto 0;
}
.modal {
  display: none;
}
.popup {
  width: 380px;
  background-color: #fff;
  padding: 30px;
}
.popup .title {
  font-size: 1.438rem;
  padding: 5px 0;
}
.form-popup {
  text-align: center;
  font-family: 'futurabookcregular', sans-serif;
  padding: 16px 0 0;
}
.form-popup__item {
  padding: 7px 0;
  font-size: 0.813rem;
  line-height: 1.063rem;
}
.form-popup__input {
  text-align: left;
  width: 100%;
  height: 40px;
  border: 1px solid #c3c3c3;
  padding: 0 15px;
}
.form-popup__textarea {
  text-align: left;
  width: 100%;
  border: 1px solid #c3c3c3;
  min-height: 145px;
  padding: 15px;
  resize: none;
}
.form-popup__btn {
  width: 180px;
}
.fancybox-slide>* {
  overflow: visible;
}
.fancybox-button__close {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 27px;
  height: 27px;
  cursor: pointer;
}
.fancybox-button__close img {
  width: 100%;
}
.fancybox-project-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  direction: ltr;
  z-index: 99997;
  box-sizing: border-box;
  -ms-filter: none;
  filter: none;
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.25s, visibility 0s;
  transition: opacity 0.25s, visibility 0s;
  color: #fff;
  padding: 20px;
}
.fancybox-project__item {
  padding: 5px 0;
}
.pace .pace-progress {
  background: #fcd25a;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}
.pace .pace-progress-inner {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #fcd25a, 0 0 5px #fcd25a;
  -ms-filter: none;
  filter: none;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -moz-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  -o-transform: rotate(3deg) translate(0px, -4px);
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 15px;
  right: 15px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #fcd25a;
  border-left-color: #fcd25a;
  border-radius: 10px;
  -webkit-animation: pace-spinner 400ms linear infinite;
  -moz-animation: pace-spinner 400ms linear infinite;
  -ms-animation: pace-spinner 400ms linear infinite;
  -o-animation: pace-spinner 400ms linear infinite;
  -webkit-animation: pace-spinner 400ms linear infinite;
  animation: pace-spinner 400ms linear infinite;
}
@media screen and (max-width: 1339px) {
  .slider__content {
    padding: 20px;
  }
}
@media screen and (max-width: 1199px) {
  .wrapper {
    width: 100%;
    padding: 0 10px;
  }
  .wrapper_min {
    width: 100%;
  }
  .head {
    padding: 0 10px;
  }
  .nav__link {
    font-size: 0.938rem;
  }
  .fancybox-link img {
    max-width: 100%;
  }
  .we-offers__item {
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .slider__content {
    font-size: 1.25rem;
    line-height: normal;
  }
  .projects {
    margin: 0 -10px;
  }
  .projects__item {
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .projects__item:nth-child(even) .projects__img {
    -webkit-order: 0;
    -ms-order: 0;
    order: 0;
  }
  .projects__img {
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    text-align: center;
  }
  .projects__desc {
    -webkit-flex: 1 1 100%;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    text-align: center !important;
    padding: 30px 0;
  }
  .projects__desc:before {
    content: '';
    border-right: 20px solid transparent !important;
    border-left: 20px solid transparent !important;
    border-top: none !important;
    border-bottom: 20px solid #f3f3f3 !important;
    position: absolute;
    top: -20px !important;
    left: 50% !important;
    right: auto !important;
    -webkit-transform: translateX(-50%) !important;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
  }
  .projects__desc:hover:before {
    border-bottom-color: #e95701 !important;
  }
  .projects__title:after {
    left: 50% !important;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .slider__scroll {
    display: none;
  }
  .slid-clients .slick-slide {
    text-align: center;
  }
  .slider .slick-dots {
    display: none !important;
  }
  .slider__content {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .header {
    height: auto;
    position: static;
  }
  .header.fixed {
    height: auto;
  }
  .container {
    padding-top: 0;
  }
  .head {
    position: relative;
    padding: 0 10px 10px;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
  }
  .nav {
    display: block;
    position: absolute;
    top: -300%;
    left: -10px;
    right: -10px;
    z-index: 99;
    background-color: #fff;
    height: auto;
    padding: 20px 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .nav.open {
    top: 100%;
  }
  .nav__link {
    display: block;
    padding: 8px 20px;
  }
  .burger {
    display: block;
  }
}
@media screen and (max-width: 570px) {
  .head {
    display: block;
    text-align: center;
    padding-top: 15px;
  }
  .call-back {
    text-align: center;
    padding: 10px 0;
  }
  .discussion-project {
    display: block;
  }
  .discussion-project__item {
    text-align: center !important;
    padding: 10px 0;
  }
  .clients {
    padding: 0;
  }
  .we-offers__title {
    font-size: 1.125rem;
  }
  .we-offers__item {
    padding: 0 10px 30px;
  }
  .we-offer {
    padding: 20px 0;
  }
  .projects__title {
    font-size: 1.125rem;
    line-height: normal;
  }
  .our-clients {
    padding: 30px 0;
  }
  .slick-slide img {
    display: inline-block;
  }
  .slid-clients .slick-dots {
    padding: 5px 0;
  }
  .slid-clients {
    margin-top: 30px;
  }
  .btn-lg {
    max-width: 100%;
  }
  .popup {
    max-width: 90%;
    padding: 15px;
  }
  .fancybox-project {
    font-size: 0.813rem;
  }
  .projects__info {
    font-size: 0.75rem;
    padding: 0 10px 10px;
  }
  .call-back__phone {
    font-size: 1.5rem;
  }
}
@-moz-keyframes go-right {
  0% {
    top: 70px;
  }
  40% {
    top: 80px;
  }
  70% {
    top: 70px;
  }
  100% {
    top: 70px;
  }
}
@-webkit-keyframes go-right {
  0% {
    top: 70px;
  }
  40% {
    top: 80px;
  }
  70% {
    top: 70px;
  }
  100% {
    top: 70px;
  }
}
@-o-keyframes go-right {
  0% {
    top: 70px;
  }
  40% {
    top: 80px;
  }
  70% {
    top: 70px;
  }
  100% {
    top: 70px;
  }
}
@keyframes go-right {
  0% {
    top: 70px;
  }
  40% {
    top: 80px;
  }
  70% {
    top: 70px;
  }
  100% {
    top: 70px;
  }
}
