.btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  transition:all .2s ease;
  border: none;
  background-color: #e95701
  color: #ffffff
  padding: 15px 10px
  font-size: 15px
  -webkit-border-radius: 0
  -moz-border-radius: 0
  border-radius: 0
  text-transform: uppercase
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {

    }
  }
  &-lg{
    width: 360px;
    padding: 18px 0;
    border: 1px solid #ffffff
    font-size: 25px

  }
  &-md{
    width: 150px;
    padding: 15px 0;
  }
  &-sm{
    width: 120px;
    padding: 12px 0;
  }
  &-xs{
    width: 100px;
    padding: 10px 0;
  }
}
