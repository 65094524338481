@font-face {
  font-family: 'icon-fond';
  src:  url('../fonts/icon-fond.eot?5e89y1');
  src:  url('../fonts/icon-fond.eot?5e89y1#iefix') format('embedded-opentype'),
          url('../fonts/icon-fond.ttf?5e89y1') format('truetype'),
          url('../fonts/icon-fond.woff?5e89y1') format('woff'),
          url('../fonts/icon-fond.svg?5e89y1#icon-fond') format('svg');
  font-weight: normal;
  font-style: normal;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon-fond' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-vfactor-logo .path1:before {
  content: "\e900";
  color: rgb(0, 0, 0);
}
.icon-vfactor-logo .path2:before {
  content: "\e901";
  margin-left: -6.2529296875em;
  color: rgb(252, 175, 26);
}
.icon-vfactor-logo .path3:before {
  content: "\e902";
  margin-left: -6.2529296875em;
  color: rgb(0, 0, 0);
}
.icon-vfactor-logo .path4:before {
  content: "\e903";
  margin-left: -6.2529296875em;
  color: rgb(0, 0, 0);
}
.icon-vfactor-logo .path5:before {
  content: "\e904";
  margin-left: -6.2529296875em;
  color: rgb(0, 0, 0);
}
.icon-vfactor-logo .path6:before {
  content: "\e905";
  margin-left: -6.2529296875em;
  color: rgb(0, 0, 0);
}
.icon-vfactor-logo .path7:before {
  content: "\e906";
  margin-left: -6.2529296875em;
  color: rgb(0, 0, 0);
}
.icon-vfactor-logo .path8:before {
  content: "\e907";
  margin-left: -6.2529296875em;
  color: rgb(0, 0, 0);
}
.icon-select:before {
  content: "\e908";
}
.icon-close:before {
  content: "\e909";
}
.icon-mouse-arrow:before {
  content: "\e90b";
}
.icon-mouse:before {
  content: "\e90c";
}
.icon-logo:before {
  content: "\e90d";
}
.icon-zoom:before {
  content: "\e90e";
}
.icon-up:before {
  content: "\e90f";
}
