
// @import "rem";

//////////////////////////////////////////////////////


html{
  font-size:$em-base;
}

body{

}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
b{

}

h1{
  font-size: rem(36);
}
h2{
  font-size: rem(30);
}
h3{
  font-size: rem(24);
}
h4{
  font-size: rem(18);
}
h5{
  font-size: rem(14);
}
h6{
  font-size: rem(12);
}

strong{

}
b{

}

p{

}

i{
  display :inline-block;
}

.left{
  float: left;
}
.right{
  float: right;
}


.cr  {float: none; clear: both; margin: 0; padding: 0; font-size: 0.1px;}


.both:after {
  clear: both;
  content: "";
  display: block;
  float: none;
  margin: 0;
  padding: 0;
}
