@import "partials/_path.styl";
@import "../../node_modules/kouto-swiss"
@import "partials/icon.styl"
// @import "partials/slick.styl"

meyer-reset()
box-sizing-reset()
normalize()


body {
  font-family font-stack( "Arial" )
  font-family: $futura-light
  font-size: rem(22)
  background-color: #ffffff
}

a{
  color: $color-dark
  outline: none !important
}
.wrapper{
  width: 1200px
  margin: 0 auto
}
.wrapper_min{
  width: 1000px
}
.burger{
  display: none
  text-align: center
}


#nav-icon {
  width: 40px;
  height: 30px;
  display: inline-block
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #e95701;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0;
}

#nav-icon span:nth-child(2) {
  top: 10px;
}

#nav-icon span:nth-child(3) {
  top: 20px;
}

#nav-icon.open span:nth-child(1) {
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon.open span:nth-child(2) {
  opacity: 0;
  left: -40px;
}

#nav-icon.open span:nth-child(3) {
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}



.title{
  font-size: rem(31)
  font-weight: normal
  text-transform: uppercase
  font-family: $futura-light
  &_center{
    text-align: center
  }
}



.header{
  background-color: #ffffff
  height: 110px
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: 9
  -webkit-transition: all .3s
  -moz-transition: all .3s
  -ms-transition: all .3s
  -o-transition: all .3s
  transition: all .3s
  &.fixed{
    background-color: rgba(255, 255, 255, .8)
    height: 80px
  }
}
.container{
  padding-top: 110px
}

.head{
  display: flex
  align-items: center
  &__logo{
    padding-top: 5px
    padding-bottom: 5px
    img{
      max-height: 100%
    }
  }
}

.btn-top-back{
  position: fixed
  bottom: 30px
  right: 20px
  z-index: 9
}

.nav{
  display: flex
  height: 100%
  align-items: center
  justify-content: center
  flex 1
  &__link{
    text-decoration: none
    text-transform: uppercase
    font-size: rem(20)
    padding: 0 20px
    &:hover{
      color: #ed6a2b
    }
  }
}


.call-back{
  text-align: right
  font-family: $futura-demi
  text-transform: uppercase
  font-weight: bold
  &__phone{
    display: block
    font-size: rem(25)
    color: $color-orange
    text-decoration: none
    padding: 0 0 5px
  }
  &__order{
    font-size: rem(12)
    border-bottom: 1px dashed
    text-decoration: none
  }
}



.slider-wrapper{
  position: relative
}



.slider{
  //height: 736px
  .slick-dots{
    position: absolute
    top: 50%
    right: 50px
    -webkit-transform: translateY(-50%)
    -moz-transform: translateY(-50%)
    -ms-transform: translateY(-50%)
    -o-transform: translateY(-50%)
    transform: translateY(-50%)
  }
  &__item{
    position: relative
  }
  &__img{
    max-width: 100%
  }
  &__content{
    position: absolute
    max-width: 670px
    top: 50%
    left: 50%
    font-family: $futura-light
    -webkit-transform: translate(-50%, -50%)
    -moz-transform: translate(-50%, -50%)
    -ms-transform: translate(-50%, -50%)
    -o-transform: translate(-50%, -50%)
    transform: translate(-50%, -50%)
    text-transform: uppercase
    font-size: rem(32)
    line-height: rem(55)
    text-align: center
    background-color: rgba(255, 255, 255, .5)
    padding: 55px 50px
  }
  &__scroll{
    position: absolute
    left: 50%
    width: 45px
    bottom: 125px
    z-index: 9
    -webkit-transform: translateX(-50%)
    -moz-transform: translateX(-50%)
    -ms-transform: translateX(-50%)
    -o-transform: translateX(-50%)
    transform: translateX(-50%)
    text-decoration: none
    color: #fff
    text-align: center
    .icon-mouse{
      position: absolute
      top: 12px
      left: 0
      font-size: rem(72)
    }
    .icon-mouse-arrow{
      position: absolute
      top: 70px
      left: 11px
      font-size: rem(32)
      animation: go-right 1.4s easing( "in-out-cubic" ) infinite
      //alternate
    }
  }
}




.slider-paging__item{
  width: 6px
  height: 50px
  background-color: #a2a2a2
  display: inline-block
  cursor: pointer
  &.slider-paging__item_h{
    width: 43px
    height: 3px
  }
}


.slick-active{
  .slider-paging__item{
    background-color: #e95701
  }
}


@keyframes go-right {
  0% {
    top: 70px
  }
  40%{
    top: 80px
  }
  70%{
    top: 70px
  }
  100% {
    top: 70px
  }
}

.clients{
  background-color: #f3f3f3
  padding: 40px 0 65px
  .title{
    padding: 50px 0 18px
  }
  &__desc{
    font-size: rem(21)
    line-height:  rem(30)
    font-family: $futura-book
    padding: 23px 0
  }
}



.we-offer{
  padding: 90px 0
}



.we-offers{
  display: flex
  flex-flow: row wrap
  align-items: center
  padding: 60px 0
  &__item{
    text-align: center
    flex: 1 1 50%
    padding:  0 40px 70px
    position: relative
    &:before{
      content: attr(data-number)
      position: absolute
      top: 0
      left:0
      text-align: center
      width: 100%
      font-size: rem(97)
      font-family: $futura-demi
      color: #e4e4e4
      font-weight: bold
    }
  }
  &__title{
    font-family: $futura-demi
    text-transform: uppercase
    font-weight: bold
    font-size: rem(24)
    padding: 38px 0
    position: relative
  }
  &__desc{
    font-size: rem(18)
    position: relative
  }
}



.projects{
  padding: 50px 0 0
  &__btn{
    flex: 1 1 100%
    text-align: center
    padding: 45px 0
  }
  &-box{

  }
  &__item{
    display: flex
    &:nth-child(even) {
      .projects__img {
        order: 1
      }
      .projects__desc {
        text-align: right
        &:before {
          left: auto
          right: -20px
          border-right: none
          border-left: 20px solid #f3f3f3
        }
        &:hover{
          &:before {
            border-left-color: #e95701
          }
        }
      }
      .projects__title {
        &:after {
          left: auto
          right: 0
        }
      }
    }
  }
  &__img{
    flex: 1 1 50%
  }
  &__desc{
    flex: 1 1 50%
    text-transform: uppercase
    font-family: $futura-book
    background-color: #f3f3f3
    display: flex
    flex-flow: column nowrap
    justify-content: center
    padding: 0 87px
    position: relative
    &:before{
      content: ''
      border-right: 20px solid #f3f3f3
      border-top: 20px solid transparent
      border-bottom: 20px solid transparent
      position: absolute
      top: 50%
      left: -20px
      -webkit-transform: translateY(-50%)
      -moz-transform: translateY(-50%)
      -ms-transform: translateY(-50%)
      -o-transform: translateY(-50%)
      transform: translateY(-50%)
      z-index: 2
    }
    &:hover{
      background-color: #e95701
      color: #fff
      .projects__info{
        -webkit-transform: scale(1, 1)
        -moz-transform: scale(1, 1)
        -ms-transform: scale(1, 1)
        -o-transform: scale(1, 1)
        transform: scale(1, 1)
        opacity: 1
      }
      &:before{
        border-right-color: #e95701
      }

    }
  }
  &__info{
    position: absolute
    top: 20px
    left: 0
    bottom: 20px
    right: 0
    padding: 0px 40px 20px
    color: #fff
    text-transform: none
    overflow: scroll;
    background-color: #e95701
    -webkit-transition: all .5s
    -moz-transition: all .5s
    -ms-transition: all .5s
    -o-transition: all .5s
    transition: all .5s
    -webkit-transform: scale(.8, .8)
    -moz-transform: scale(.8, .8)
    -ms-transform: scale(.8, .8)
    -o-transform: scale(.8, .8)
    transform: scale(.8, .8)
    opacity: 0
  }
  &__title{
    font-size: rem(30)
    line-height: rem(35)
    font-weight: bold
    position: relative
    margin-bottom: 40px
    &:after{
      content: ''
      width: 72px
      height: 3px
      background-color: $color-dark
      position: absolute
      bottom: -19px
      left:0
    }
  }
  &__text{
    font-size: rem(15)
    line-height: rem(20)
    color: #545454
  }
}



.fancybox-hidden{
  display: none
}

.fancybox-link{
  display: inline-block
  text-decoration: none
  position: relative
  overflow: hidden
  &:before{
    content: ''
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-image: url("../images/svg/zoom.svg")
    background-color: rgba(0, 0, 0, .5)
    background-position: center
    background-repeat: no-repeat
    opacity: 0
    -webkit-transition: all .7s
    -moz-transition: all .7s
    -ms-transition: all .7s
    -o-transition: all .7s
    transition: all .7s
    z-index: 1
  }
  &:hover{
    &:before{
      opacity: 1
    }
    img{
      -webkit-transform: scale(1.1, 1.1)
      -moz-transform: scale(1.1, 1.1)
      -ms-transform: scale(1.1, 1.1)
      -o-transform: scale(1.1, 1.1)
      transform: scale(1.1, 1.1)
    }
  }
  img{
    display: block
    -webkit-transition: all .5s
    -moz-transition: all .5s
    -ms-transition: all .5s
    -o-transition: all .5s
    transition: all .5s
  }
}


.our-clients{
  padding: 87px 0 124px
}

.slid-clients{
  max-height: 250px
  margin-top: 60px
  .slick-list{
    max-height: 250px
  }
  .slick-dots{
    text-align: center
    padding: 30px 0
    li{
      display: inline-block
      padding: 0 3px
    }
  }
  &__item{

  }
  &__img{

  }
}
  
.discuss-project{
  padding: 40px 0
  background-color: #e95701
}


.discussion-project{
  display: flex
  justify-content: center
  align-items: center
  color: #ffffff
  font-size: rem(18)
  line-height: rem(22)
  text-transform: uppercase
  &__item{
    flex: 1 1 50%
    &:first-child{
      padding: 0 40px
      text-align: right
    }
  }
  .btn{
    -webkit-transition: all .3s
    -moz-transition: all .3s
    -ms-transition: all .3s
    -o-transition: all .3s
    transition: all .3s
    &:hover{
      border-color: #e95701
      color: #e95701
      background-color: #fff
    }
  }
}



.gallery-project{
  padding: 36px 0 15px
  &_bg{
    background:  url('../images/bg-secrion-1.jpg') center/cover  no-repeat
  }
}



.form-gallery{
  text-align: center
  padding: 15px 0
}



.styled-select {
  background: #ffffff url('../images/svg/select.svg') no-repeat 96% 13px;
  height: 34px;
  overflow: hidden;
  width: 300px;
  -webkit-border-radius: 0
  -moz-border-radius: 0
  border-radius: 0
  border: 0
  padding: 0 25px 0 10px
  font-size: rem(18)
  text-align: left
  // letter-spacing: rem(-1.7)
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}



.styled-select select {
  background: transparent;
  border: none;
  font-size: 14px;
  height: 29px;
  padding: 5px;
  width: 268px;

}


.footer{
  text-align: center
  font-size: rem(13)
  line-height: rem(18)
  padding: 50px 0
  a{
    font-size: rem(10)
  }
  &__logo{
    width: 92px
    display: block
    margin: 20px auto 0
  }
}



.modal{
  display: none
}




.popup{
  width: 380px
  background-color: #fff
  padding: 30px
  .title{
    font-size: rem(23)
    padding: 5px 0
  }
}




.form-popup{
  text-align: center
  font-family: $futura-book
  padding: 16px 0 0
  &__item{
    padding: 7px 0
    font-size: rem(13)
    line-height: rem(17)
  }
  &__input{
    text-align: left
    width: 100%
    height: 40px
    border: 1px solid #c3c3c3
    padding: 0 15px
  }
  &__textarea{
    text-align: left
    width: 100%
    border: 1px solid #c3c3c3
    min-height: 145px
    padding: 15px
    resize: none
  }
  &__btn{
    width: 180px
  }
}


.fancybox-slide>*{
  overflow: visible
}


.fancybox-button__close{
  position: absolute
  top: -10px
  right: -10px
  width: 27px
  height: 27px
  cursor: pointer
  img{
    width: 100%
  }
}

.fancybox-project-wrap{
  position: absolute;
  bottom: 0
  left: 0
  right: 0
  direction: ltr;
  z-index: 99997;
  box-sizing: border-box;
  opacity: 1;
  visibility: visible;
  transition: opacity .25s, visibility 0s
  color: #fff
  padding: 20px
}

.fancybox-project{
  &__item{
    padding: 5px 0
  }
}

.pace .pace-progress {
  background: #fcd25a;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.pace .pace-progress-inner {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #fcd25a, 0 0 5px #fcd25a;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -moz-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  -o-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 15px;
  right: 15px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #fcd25a;
  border-left-color: #fcd25a;
  border-radius: 10px;
  -webkit-animation: pace-spinner 400ms linear infinite;
  -moz-animation: pace-spinner 400ms linear infinite;
  -ms-animation: pace-spinner 400ms linear infinite;
  -o-animation: pace-spinner 400ms linear infinite;
  animation: pace-spinner 400ms linear infinite;
}
@import 'partials/media.styl'

