@import '_typography.styl';
@import '_btn.styl';

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 15, 2018 */


// font
$futura-light = 'futuralightcregular', sans-serif
$futura-book = 'futurabookcregular', sans-serif
$futura-demi = 'futurademicregular', sans-serif

// color
$color-dark = #000
$color-orange = #ed6a2b